import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { AutosizeModule } from 'ngx-autosize';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfigBackService, Country } from '@dougs/core/config-back';
import { Attachment } from '@dougs/core/files';
import { FormService } from '@dougs/core/form';
import { generateUuidV4 } from '@dougs/core/utils';
import {
  AddressAutocompleteComponent,
  AddressData,
  AddressFieldsEnum,
  AddressGouvFeatureProperties,
  CharacterCountDirective,
  CheckboxComponent,
  ControlFormFieldDirective,
  CopyToClipboardDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  ModalService,
  PanelInfoComponent,
  PrefixFormFieldDirective,
  RadioComponent,
  RadioGroupComponent,
  SelectComponent,
  SelectOptionComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { Field } from '@dougs/fields/dto';
import { UserStateService } from '@dougs/user/shared';
import { HelpFieldModalComponent } from '../modals/help-field-modal/help-field-modal.component';
import { MissingFieldsBadgeService } from '../services/missing-fields-badge.service';

@Component({
  selector: 'dougs-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    MissingFieldsBadgeService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FieldComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    FormFieldComponent,
    LabelFormFieldDirective,
    FormsModule,
    ControlFormFieldDirective,
    SuffixFormFieldDirective,
    ErrorFormFieldDirective,
    NgTemplateOutlet,
    SelectComponent,
    NgFor,
    SelectOptionComponent,
    CheckboxComponent,
    RadioGroupComponent,
    RadioComponent,
    InputDatepickerComponent,
    ReactiveFormsModule,
    FileInputComponent,
    FilePillComponent,
    AutosizeModule,
    CharacterCountDirective,
    PanelInfoComponent,
    AddressAutocompleteComponent,
    AsyncPipe,
    LottieComponent,
    PrefixFormFieldDirective,
    CopyToClipboardDirective,
  ],
})
export class FieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  touched = false;
  isDisabled = false;
  generatedId = generateUuidV4();
  changeSubject: Subject<string> = new Subject<string>();
  subscriptionChange!: Subscription;
  @ContentChild('fieldError') fieldErrorTemplate?: TemplateRef<HTMLElement>;
  @Input() actionButtonIcon?: string;
  @Input() showActionButton?: boolean;
  @Input() isAdmin?: boolean;
  @Input() inputIsAdmin?: boolean;
  @Input() showLabel?: boolean = true;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() vertical?: boolean;
  @Input() characterLimit?: number | null;
  @Input() useAddressAutocomplete = false;
  @Input() useAddressContext = false;
  @Input() showAddressNotListedOption = false;
  @Input() addressFieldsToDisplay: AddressFieldsEnum[] = [];
  @Input() canCopy = false;
  @Input() noWrap = false;
  @Input()
  set showMissingBadge(showMissingBadge: boolean) {
    this.missingFieldsBadgeService.setShowMissingBadge(showMissingBadge);
  }
  @Output() selectAddress: EventEmitter<AddressGouvFeatureProperties> =
    new EventEmitter<AddressGouvFeatureProperties>();
  @Output() manualAddressEntry: EventEmitter<AddressData> = new EventEmitter<AddressData>();

  @HostBinding('class.flex')
  @Input()
  flex = false;
  @Input()
  checkboxAsRadio = false;
  @Input() trackSelectOptionsByLabel = false;

  @Output() actionButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteFile: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() uploadFile: EventEmitter<FileList | File> = new EventEmitter<FileList | File>();
  control!: UntypedFormControl;
  labelId = '';

  options: AnimationOptions = {
    path: 'animations/missing-fields-anchor.json',
    loop: true,
  };

  constructor(
    protected readonly modalService: ModalService,
    public readonly formService: FormService,
    protected readonly injector: Injector,
    protected readonly cdr: ChangeDetectorRef,
    public readonly configBackService: ConfigBackService,
    public readonly missingFieldsBadgeService: MissingFieldsBadgeService,
    public readonly userStateService: UserStateService,
  ) {}

  protected _field!: Field;

  get field(): Field {
    return this._field;
  }

  @Input()
  set field(field: Field) {
    this._field = field;

    this.labelId = `${field.field.replace(/\./g, '')}-${field.modelName}`;

    this.missingFieldsBadgeService.setField(field);

    if (field?.input?.type === 'select') {
      this.resetValueIfNotAvailable();
    }

    this.control?.updateValueAndValidity({ emitEvent: false });
  }

  protected _value!: string;

  get value(): any {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.changeSubject.next(value);
  }

  onChange: (value: string) => void = () => true;

  onTouched: () => void = () => true;

  onBlur(): void {
    this.onTouched();
  }

  onSelectChange(e: string): void {
    this._value = e;
    this.onChange(e);
    this.onTouched();
  }

  ngOnInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.control = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.control = (ngControl as FormControlDirective).form as UntypedFormControl;
    }

    this.subscriptionChange = this.changeSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.onChange(value);
      if (this.field.input.type !== 'textarea' && this.field.input.type !== 'text') {
        this.onTouched();
      }
      this.cdr.markForCheck();
    });
  }

  showHelpModal(): void {
    if (!this.field.description) {
      return;
    }

    this.modalService.open(HelpFieldModalComponent, {
      data: {
        title: this.field.description.title,
        body: this.field.description.message,
      },
    });
  }

  resetValueIfNotAvailable(): void {
    if (this.value && !this.field?.input?.labels?.items?.find((item) => item.value === this.value)) {
      this.value = '';
    }
  }

  writeValue(value: string): void {
    this._value = value;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  trackByValue(index: number, option: { value: number | string; label: string }): number | string {
    return option.value;
  }

  trackByLabel(index: number, option: { value: number | string; label: string }): number | string {
    return option.label;
  }

  trackByOption(index: number): number {
    return index;
  }

  trackByCountryValue(index: number, item: Country): string {
    return item.value;
  }

  ngOnDestroy(): void {
    this.subscriptionChange.unsubscribe();
  }
}
