<ng-container *ngIf="field?.isAvailable">
  <ng-lottie
    *ngIf="missingFieldsBadgeService.shouldShowMissingFieldsBadge$ | async"
    width="16px"
    height="16px"
    containerClass="missing-field-animation"
    [options]="options"
  ></ng-lottie>
  <dougs-form-field *ngIf="field.input?.type === 'text'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
        [id]="labelId"
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <i
      dougsFormFieldPrefix
      class="fal fa-copy color-admin"
      *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin && canCopy"
      [dougsCopyToClipboard]="value"
    ></i>
    <input
      #ngModel="ngModel"
      (blur)="onBlur()"
      [(ngModel)]="value"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [pattern]="field.input?.pattern"
      [placeholder]="field.input.placeholder || ''"
      [required]="field.input?.isRequired"
      dougsAddressAutocomplete
      [showAddressAutocomplete]="useAddressAutocomplete"
      [useAddressContext]="useAddressContext"
      [showAddressNotListedOption]="showAddressNotListedOption"
      [addressFieldsToDisplay]="addressFieldsToDisplay"
      (manualAddressEntry)="manualAddressEntry.emit($event)"
      (selectAddress)="selectAddress.emit($event)"
      dougsFormFieldControl
      type="text"
      [attr.data-cy]="field.field"
    />
    <i (click)="actionButtonClick.emit()" *ngIf="showActionButton" [class]="actionButtonIcon" dougsFormFieldSuffix></i>
    <span *ngIf="formService.isControlInvalid(control) || formService.isNgModelInvalid(ngModel)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required') || ngModel.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <dougs-form-field *ngIf="field.input?.type === 'email'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
        [id]="labelId"
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <i
      dougsFormFieldPrefix
      class="fal fa-copy color-admin"
      *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin && canCopy"
      [dougsCopyToClipboard]="value"
    ></i>
    <input
      #ngModel="ngModel"
      (blur)="onBlur()"
      [(ngModel)]="value"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [pattern]="field.input?.pattern"
      [placeholder]="field.input.placeholder || ''"
      [required]="field.input?.isRequired"
      dougsFormFieldControl
      type="email"
      [attr.data-cy]="field.field"
    />
    <i (click)="actionButtonClick.emit()" *ngIf="showActionButton" [class]="actionButtonIcon" dougsFormFieldSuffix></i>
    <span *ngIf="formService.isControlInvalid(control) || formService.isNgModelInvalid(ngModel)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required') || ngModel.hasError('required')">Ce champ est requis</span>
      <span *ngIf="control?.hasError('email') || ngModel.hasError('email')">Email invalide</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <dougs-form-field *ngIf="field.input?.type === 'number'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [id]="labelId"
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <input
      #ngModel="ngModel"
      [(ngModel)]="value"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [max]="field.input?.max"
      [min]="field.input?.min"
      [placeholder]="field.input.placeholder || ''"
      [required]="field.input?.isRequired"
      dougsFormFieldControl
      type="number"
      [attr.data-cy]="field.field"
    />
    <i (click)="actionButtonClick.emit()" *ngIf="showActionButton" [class]="actionButtonIcon" dougsFormFieldSuffix></i>
    <span *ngIf="formService.isControlInvalid(control) || formService.isNgModelInvalid(ngModel)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required') || ngModel.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <dougs-form-field *ngIf="field.input?.type === 'select'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
        [id]="labelId"
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <dougs-select
      (ngModelChange)="onSelectChange($event)"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [ngModel]="value"
      [placeholder]="field.input?.labels?.defaultText || ''"
      [required]="field.input?.isRequired"
      dougsFormFieldControl
      maxHeight="250"
      [attr.data-cy]="field.field"
    >
      <dougs-select-option
        *ngFor="
          let option of field.input.labels.items;
          trackBy: trackSelectOptionsByLabel ? trackByLabel : trackByValue
        "
        [value]="option.value"
      >
        {{ option.label }}
      </dougs-select-option>
      <dougs-select-option
        *ngFor="
          let option of field.input.labels.itemsUnavailable;
          trackBy: trackSelectOptionsByLabel ? trackByLabel : trackByValue
        "
        [value]="option.value"
        [disabled]="true"
        [tooltip]="option.unavailableMessage"
      >
        {{ option.label }}
      </dougs-select-option>
    </dougs-select>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <ng-container *ngIf="field.input?.type === 'switch'">
    <div *ngIf="showLabel" class="field-label-wrapper">
      <label
        [id]="labelId"
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <dougs-checkbox
      (ngModelChange)="onSelectChange($event)"
      *ngIf="!checkboxAsRadio"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [isAdmin]="isAdmin || inputIsAdmin"
      [ngModel]="value"
      [noText]="field.input?.falseText"
      [showYesNo]="true"
      [yesText]="field.input?.trueText"
      appearance="toggle"
      [attr.data-cy]="field.field"
    ></dougs-checkbox>
    <dougs-radio-group
      (ngModelChange)="onSelectChange($event)"
      *ngIf="checkboxAsRadio"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [isAdmin]="isAdmin || inputIsAdmin"
      [ngModel]="value"
      [vertical]="vertical"
      [noWrap]="noWrap"
      appearance="yesNo"
      [attr.data-cy]="field.field"
    >
      <dougs-radio [value]="true">{{ field.input?.trueText }}</dougs-radio>
      <dougs-radio [value]="false">{{ field.input?.falseText }}</dougs-radio>
    </dougs-radio-group>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </ng-container>
  <dougs-form-field *ngIf="field.input?.type === 'checkbox'" [noMargin]="true" [size]="size">
    <dougs-checkbox
      (ngModelChange)="onSelectChange($event)"
      [disabled]="!field.isEditable || isDisabled"
      [dougsFormFieldLabel]="showLabel"
      [id]="generatedId"
      [isAdmin]="isAdmin || inputIsAdmin"
      [label]="field?.label"
      [ngModel]="value"
      appearance="classic"
      [attr.data-cy]="field.field"
    ></dougs-checkbox>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <div *ngIf="field.input?.type === 'yesno'">
    <div class="yesno-field">
      <label
        [id]="labelId"
        *ngIf="showLabel"
        [innerHTML]="field?.label"
        class="self-center mr-8"
        dougsFormFieldLabel
      ></label>
      <dougs-radio-group
        (ngModelChange)="onSelectChange($event)"
        [disabled]="!field.isEditable || isDisabled"
        [id]="generatedId"
        [ngModel]="value"
        [noWrap]="noWrap"
        appearance="yesNo"
        [attr.data-cy]="field.field"
      >
        <dougs-radio [value]="true">Oui</dougs-radio>
        <dougs-radio [noDesign]="true" [value]="false">Non</dougs-radio>
      </dougs-radio-group>
    </div>
    <p *ngIf="field.input?.comment" class="tiny">{{ field.input.comment }}</p>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </div>
  <dougs-form-field *ngIf="field.input?.type === 'radio'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [id]="labelId"
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <dougs-radio-group
      [noWrap]="noWrap"
      [(ngModel)]="value"
      [disabled]="!field.isEditable || isDisabled"
      [vertical]="vertical"
      [attr.data-cy]="field.field"
    >
      <dougs-radio *ngFor="let option of field.input.options; trackBy: trackByOption" [value]="option.value">
        {{ option.label }}
      </dougs-radio>
      <dougs-radio *ngFor="let option of field.input?.labels?.items; trackBy: trackByOption" [value]="option.value">
        {{ option.label }}
      </dougs-radio>
    </dougs-radio-group>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <!-- TODO Adding help on datepicker -->
  <ng-container *ngIf="field.input?.type === 'date'">
    <dougs-input-datepicker
      *ngIf="!control"
      [labelId]="labelId"
      [(ngModel)]="value"
      [disabled]="!field.isEditable || isDisabled"
      [isAdmin]="isAdmin"
      [label]="field?.label"
      [noMargin]="true"
      [placeholder]="field.input?.placeholder"
      [required]="field.input?.isRequired"
      [size]="size"
      [attr.data-cy]="field.field"
    ></dougs-input-datepicker>
    <dougs-input-datepicker
      *ngIf="control"
      [labelId]="labelId"
      [disabled]="!field.isEditable || isDisabled"
      [formControl]="control"
      [isAdmin]="isAdmin"
      [label]="field?.label"
      [noMargin]="true"
      [placeholder]="field.input?.placeholder"
      [required]="field.input?.isRequired"
      [size]="size"
      [attr.data-cy]="field.field"
    ></dougs-input-datepicker>
  </ng-container>
  <ng-container *ngIf="field.input?.type === 'file'">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [for]="generatedId"
        [id]="labelId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <div *ngIf="!value || value?.length === 0; else withAttachment" class="file-list">
      <dougs-file-input
        (uploadFiles)="uploadFile.emit($event)"
        *ngIf="field.isEditable"
        [fileInputText]="field.input?.buttonText ?? field?.input?.addFileText"
        [multiple]="field?.input?.allowMultiple"
        [attr.data-cy]="field.field"
      ></dougs-file-input>
    </div>
    <ng-template #withAttachment>
      <div class="file-list">
        <dougs-file-input
          (uploadFiles)="uploadFile.emit($event)"
          *ngIf="field?.input?.allowMultiple && field.isEditable"
          [fileInputText]="field.input?.buttonText ?? field?.input?.addFileText"
          [attr.data-cy]="field.field"
        ></dougs-file-input>
        <dougs-file-input
          (uploadFiles)="uploadFile.emit($event)"
          *ngIf="!field?.input?.allowMultiple && field?.input?.updateFileText && field.isEditable"
          [fileInputText]="field?.input?.updateFileText"
          [multiple]="false"
          [attr.data-cy]="field.field"
        ></dougs-file-input>
        <ng-container *ngIf="value?.length">
          <dougs-file-pill
            (deleteFile)="deleteFile.emit($event)"
            *ngFor="let attachment of value"
            [attachment]="attachment"
            [canDelete]="field?.input?.isDeletable && field.isEditable"
          ></dougs-file-pill>
        </ng-container>
        <dougs-file-pill
          (deleteFile)="deleteFile.emit($event)"
          *ngIf="!value?.length"
          [attachment]="value"
          [canDelete]="field?.input?.isDeletable && field.isEditable"
        ></dougs-file-pill>
      </div>
    </ng-template>
  </ng-container>
  <dougs-form-field *ngIf="field.input?.type === 'textarea'" [noMargin]="true" [size]="size">
    <div *ngIf="showLabel" class="field-label-wrapper" dougsFormFieldLabel>
      <label
        [id]="labelId"
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <i
      dougsFormFieldPrefix
      class="fal fa-copy color-admin"
      *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin && canCopy"
      [dougsCopyToClipboard]="value"
    ></i>
    <textarea
      [(ngModel)]="value"
      [disabled]="!field.isEditable"
      [id]="generatedId"
      [maxlength]="characterLimit"
      [placeholder]="field.input.placeholder || ''"
      [required]="field.input?.isRequired"
      (blur)="onBlur()"
      autosize
      dougsFormFieldControl
      [attr.data-cy]="field.field"
    ></textarea>
    <p *ngIf="characterLimit && value" dougsFormFieldCharacterCount>{{ value.length }}/{{ characterLimit }}</p>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </dougs-form-field>
  <ng-container *ngIf="field.input?.type === 'country'">
    <div *ngIf="showLabel" class="field-label-wrapper">
      <label
        [id]="labelId"
        [for]="generatedId"
        [innerHTML]="field?.label"
        [isAdmin]="isAdmin"
        dougsFormFieldLabel
      ></label>
      <i (click)="showHelpModal()" *ngIf="field.description" class="fal fa-question-circle help ml-4"></i>
    </div>
    <dougs-select
      (ngModelChange)="onSelectChange($event)"
      [searchable]="true"
      [disabled]="!field.isEditable || isDisabled"
      [id]="generatedId"
      [ngModel]="value"
      [placeholder]="field.input.placeholder"
      [required]="field.input?.isRequired"
      dougsFormFieldControl
      maxHeight="250"
      [attr.data-cy]="field.field"
    >
      <dougs-select-option
        *ngFor="let country of configBackService.countries$ | async; trackBy: trackByCountryValue"
        [value]="country.value"
      >
        {{ country.label }}
      </dougs-select-option>
    </dougs-select>
    <span *ngIf="formService.isControlInvalid(control)" dougsFormFieldError>
      <span *ngIf="control?.hasError('required')">Ce champ est requis</span>
      <ng-container *ngIf="fieldErrorTemplate" [ngTemplateOutlet]="fieldErrorTemplate"></ng-container>
    </span>
  </ng-container>
  <dougs-panel-info
    *ngIf="
      field?.error && field.error.type !== 'required' && field.error.type !== 'info' && field.error.type !== 'warning'
    "
    [small]="true"
    class="mt-4"
    type="error"
  >
    <span [innerHTML]="field?.error?.message"></span>
  </dougs-panel-info>
  <dougs-panel-info *ngIf="field?.error && field.error.type === 'info'" [small]="true" class="mt-4" type="info">
    <span [innerHTML]="field?.error?.message"></span>
  </dougs-panel-info>
  <dougs-panel-info *ngIf="field?.error && field.error.type === 'warning'" [small]="true" class="mt-4" type="warning">
    <span [innerHTML]="field?.error?.message"></span>
  </dougs-panel-info>
</ng-container>
