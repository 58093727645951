import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Intercom } from '@supy-io/ngx-intercom';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';

@Component({
  selector: 'dougs-help-field-modal',
  templateUrl: './help-field-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    ModalFooterDirective,
    ButtonComponent,
  ],
})
export class HelpFieldModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      title: string;
      body: string;
      contactUsMessage?: string;
    },
    private readonly intercom: Intercom,
  ) {}

  openIntercom(e: Event): void {
    e.preventDefault();
    this.intercom.show(this.data.contactUsMessage);
  }
}
