import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { mergeObjects } from '@dougs/core/utils';
import {
  AvatarMessageComponent,
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { AllFields, Field, Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FileStateService } from '@dougs/settings/shared';
import { FieldComponent } from '../../components/field.component';

@Component({
  selector: 'dougs-missing-fields-modal',
  templateUrl: './social-missing-fields-modal.component.html',
  styleUrls: ['./social-missing-fields-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    AvatarMessageComponent,
    NgFor,
    FieldComponent,
    FormsModule,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class SocialMissingFieldsModalComponent implements OnInit {
  missingFields: Field[] = [];
  public activeCompanyUpdated$!: Observable<Company>;
  public formIsDirty = false;
  public company!: Company;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      company: Company;
    },
    public readonly companyStateService: CompanyStateService,
    public readonly fileStateService: FileStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    this.company = this.data.company;
    await this.refreshMissingFields();
    this.activeCompanyUpdated$ = this.companyStateService.activeCompany$.pipe(
      tap((company: Company) => (this.company = company)),
    );
  }

  onModelChange(newModelValue: any, missingField: Field): void {
    this.formIsDirty = true;
    const updatedCompany: Partial<Company> = _.set({}, missingField.field, newModelValue);
    this.company = mergeObjects(this.company, updatedCompany);
  }

  async uploadFiles(company: Company, field: Field, companyKey: string, files: File | FileList): Promise<void> {
    this.formIsDirty = true;
    const fileType: string = field.input?.fileType || field.input?.singularDocumentType || '';
    await this.fileStateService.uploadFiles(company, fileType, companyKey, files);
  }

  async refreshMissingFields(): Promise<void> {
    const allFields: AllFields = await this.fieldsStateService.getCompanyFieldsByGroupIds(this.company, [
      'advancedSocialParameters',
    ]);
    const missingFields: Fields = allFields.missingFields;
    this.missingFields = Object.keys(missingFields)
      .map((key) => missingFields[key])
      .filter((missingField) => !!missingField.input && missingField.isAvailable);
    this.cdr.markForCheck();
  }

  async submit(): Promise<void> {
    const updatedCompany: Company = mergeObjects(this.companyStateService.activeCompany, this.company);
    await this.companyStateService.updateCompany(updatedCompany);
    this.formIsDirty = false;
    this.company = updatedCompany;
    await this.refreshMissingFields();
    if (this.missingFields?.length < 1) {
      this.modalRef.close(true);
    }
  }

  trackByField(index: number, item: Field): string {
    return item.field;
  }

  getFieldKey(field: string): string {
    if (!field.includes('.')) {
      return field;
    }
    const keys: string[] = field.split('.');
    return keys[keys.length - 1];
  }

  getCompanyAttribute(missingField: Field) {
    return missingField?.field ? _.get(this.company, missingField.field) : null;
  }
}
