<div dougsModalTitle>
  <h6>Complétez vos paramètres de gestion sociale</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <ng-container *ngIf="activeCompanyUpdated$ | async"></ng-container>
  <div class="info-container mb-24">
    <dougs-avatar-message
      size="medium"
      [avatarUrl]="'images/avatar/small-claire.jpg'"
      [fullName]="'Claire, Gestionnaire de paie'"
    >
      <span>
        Bonjour, il ne manque plus que quelques éléments pour que nous puissions réaliser vos fiches de paie :
      </span>
    </dougs-avatar-message>
  </div>
  <div class="form-container p-16">
    <div class="form" *ngIf="missingFields">
      <ng-container *ngFor="let missingField of missingFields; trackBy: trackByField">
        <dougs-field
          class="mb-16"
          [ngModel]="getCompanyAttribute(missingField)"
          [field]="missingField"
          (ngModelChange)="onModelChange($event, missingField)"
          (uploadFile)="uploadFiles(company, missingField, getFieldKey(missingField.field), $event)"
          (deleteFile)="
            fileStateService.deleteFiles(
              company,
              $event,
              getFieldKey(missingField.field),
              missingField?.input?.allowMultiple
            )
          "
        ></dougs-field>
      </ng-container>
    </div>
  </div>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Plus tard</dougs-button>
  <dougs-button type="submit" (click)="submit()" [disabled]="!formIsDirty">Enregistrer</dougs-button>
</div>
