<div dougsModalTitle>
  <h6 [innerHTML]="data.title"></h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent [innerHTML]="data.body"></div>
<div dougsModalContent *ngIf="data.contactUsMessage">
  <a class="ml-4" href="#" (click)="openIntercom($event)">Contactez-nous</a>
</div>
<div dougsModalFooter>
  <dougs-button [dougsModalClose]="true" color="secondary">J'ai compris</dougs-button>
</div>
