<form [formGroup]="formGroup" (ngSubmit)="submit()">
  <div dougsModalTitle>
    <h6 class="mr-16">Manque d'information pour procéder au paiement</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <ng-container *ngIf="formGroupChanged$ | async"></ng-container>
    <p class="mb-16">
      Avant de continuer, merci de compléter dans vos paramètres, les informations de votre entreprise ci-dessous :
    </p>
    <div class="form-layout mb-16" *ngFor="let missingField of missingFields; trackBy: trackByField">
      <dougs-field [formControlName]="missingField.field" [field]="missingField"></dougs-field>
    </div>
  </div>
  <div align="center" dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit" [disabled]="formGroup.invalid" data-cy="missing-fields-submit-button"
      >Continuer</dougs-button
    >
  </div>
</form>
