import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { concatMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { mergeObjects } from '@dougs/core/utils';
import {
  ButtonComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
} from '@dougs/ds';
import { AllFields, Field, Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';
import { FieldComponent } from '../../components/field.component';

@Component({
  selector: 'dougs-missing-fields-modal',
  templateUrl: './missing-fields-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    NgIf,
    NgFor,
    FieldComponent,
    ModalFooterDirective,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class MissingFieldsModalComponent implements OnInit {
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  formGroupChanged$!: Observable<void>;
  missingFields: Field[] = [];

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      company: Company;
    },
    private readonly companyStateService: CompanyStateService,
    private readonly fieldsStateService: FieldsStateService,
    private readonly modalRef: ModalRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.refreshMissingFields();
    this.updateFormGroupWithMissingFields();
    this.formGroupChanged$ = this.formGroup.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => this.updateCompanyModel()),
      concatMap(() => from(this.refreshMissingFields())),
      tap(() => this.updateFormGroupWithMissingFields()),
    );
  }

  async refreshMissingFields(): Promise<void> {
    const allFields: AllFields = await this.fieldsStateService.getCompanyFieldsByGroupIds(this.data.company, [
      'payment',
    ]);
    const missingFields: Fields = allFields.missingFields;
    const companyFields: Fields = allFields.company;
    const effectiveMissingFieldsKeys: string[] = Object.keys(missingFields).filter(
      (key) => !!companyFields[key] && !this.missingFields.find((f) => f.field === key),
    );
    this.missingFields = [...this.missingFields, ...effectiveMissingFieldsKeys.map((key) => missingFields[key])];
    this.cdr.markForCheck();
  }

  async submit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      await this.companyStateService.updateCompany(this.data.company);
      this.modalRef.close(true);
    }
  }

  trackByField(index: number, item: Field): string {
    return item.field;
  }

  private updateCompanyModel(): void {
    this.data.company = mergeObjects(
      this.companyStateService.activeCompany,
      this.formatFormGroupValueWithNestedAttributes(),
    );
  }

  private updateFormGroupWithMissingFields(): void {
    for (const missingField of this.missingFields) {
      if (!this.formGroup.get(missingField.field)?.dirty) {
        this.formGroup.addControl(missingField.field, new UntypedFormControl(null, [Validators.required]), {
          emitEvent: false,
        });
      }
    }
  }

  private formatFormGroupValueWithNestedAttributes() {
    return Object.keys(this.formGroup.value).reduce(
      (formattedAccValue: Record<string, unknown>, currentFormControlKey: string) => {
        const isNested: boolean = currentFormControlKey.includes('.');
        if (!isNested) {
          formattedAccValue[currentFormControlKey] = this.formGroup.value[currentFormControlKey];
          return formattedAccValue;
        }
        const [nestedKey, attributeKey]: string[] = currentFormControlKey.split('.');
        formattedAccValue[nestedKey] = formattedAccValue[nestedKey] || {};
        const nestedFormGroup: Record<string, unknown> = formattedAccValue[nestedKey] as Record<string, unknown>;
        nestedFormGroup[attributeKey] = this.formGroup.value[currentFormControlKey];
        return formattedAccValue;
      },
      {},
    );
  }
}
