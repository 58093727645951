import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { Field, Fields } from '@dougs/fields/dto';
import { FieldsStateService } from '@dougs/fields/shared';

@Injectable()
export class MissingFieldsBadgeService {
  private readonly field: BehaviorSubject<Field | null> = new BehaviorSubject<Field | null>(null);

  private readonly showMissingBadge: WritableSignal<boolean> = signal<boolean>(true);
  private readonly showMissingBadge$: Signal<boolean> = this.showMissingBadge.asReadonly();

  shouldShowMissingFieldsBadge$ = combineLatest([
    this.field.asObservable(),
    this.fieldsStateService.missingFields$,
    this.fieldsStateService.partnerMissingFields$,
    this.fieldsStateService.carMissingFields$,
    this.fieldsStateService.loanMissingFields$,
    this.fieldsStateService.establishmentMissingFields$,
  ]).pipe(
    filter((field) => field !== null),
    map(
      ([
        field,
        companyMissingFields,
        partnerMissingFields,
        carMissingFields,
        loanMissingFields,
        establishmentMissingFields,
      ]) =>
        this.shouldShowMissingFieldsBadge(
          field,
          companyMissingFields,
          partnerMissingFields,
          carMissingFields,
          loanMissingFields,
          establishmentMissingFields,
        ),
    ),
  );

  constructor(private readonly fieldsStateService: FieldsStateService) {}

  setField(field: Field): void {
    this.field.next(field);
  }

  setShowMissingBadge(showMissingBadge: boolean): void {
    this.showMissingBadge.set(showMissingBadge);
  }

  shouldShowMissingFieldsBadge(
    field: Field | null,
    companyMissingFields: Fields,
    partnerMissingFields: Fields,
    carMissingFields: Fields,
    loanMissingFields: Fields,
    establishmentMissingFields: Fields,
  ): boolean {
    if (!field || !this.showMissingBadge$()) {
      return false;
    }

    return (
      this.isFieldMissing(field, companyMissingFields) ||
      this.isFieldMissing(field, partnerMissingFields) ||
      this.isFieldMissing(field, carMissingFields) ||
      this.isFieldMissing(field, loanMissingFields) ||
      this.isFieldMissing(field, establishmentMissingFields)
    );
  }

  private isFieldMissing(field: Field, missingFields: Fields): boolean {
    if (!missingFields) {
      return false;
    }

    const missingField: Field = missingFields[field.field];

    return missingField && missingField.modelName === field.modelName;
  }
}
